import {
    cssVal,
    getDataByCodePostal, getDataBySiren, hidden,
    newInput,
    tryToDate, visible,
} from "@/data/helpers";
import {def} from "@/data/dictionnaire";

export const capital = {
    title: "Modification de capital",
    texte: "<strong>{{societeGroup.societeDenomination}}</strong>[[ ({{societeGroup.sigle}})]] {{societeGroup.societeFormeJuridique}}, au capital de {{societeGroup.societeCapital}} €, Siège : {{siegeSocialGroup.societeAdresse}}[[, " +
        "{{siegeSocialGroup.societeAdresse2}}]] {{siegeSocialGroup.societeCodePostal}} {{siegeSocialGroup.societeVille}}, RCS : <span style='text-transform: uppercase'>{{societeGroup.societeRcsVille}}</span> N° {{societeGroup.societeRcs}}. <br>" +
        "Aux termes {{acteGroup.auTermeAssembleDecisionActe}} en date du {{acteGroup.dateDu}}, le capital social a été {{modificationCapitalGroup}}€, pour être porté à {{modificationCapitalGroup.nouveauCapital}}€, à compter du {{acteGroup.dateEffet}}. RCS {{societeGroup.societeRcsVille}}.",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "modificationCapitalGroup":
                if(varText['modificationCapitalGroup.typeModification'] === 'réduit') {
                    tmpText = ` réduit de `+ cssVal(varText['modificationCapitalGroup.montantReduction']);
                } else {
                    tmpText = ` augmenté de `+ cssVal(varText['modificationCapitalGroup.montantAugmentation']);
                }
                break;
            case "acteGroup.auTermeAssembleDecisionActe":
                if(varText['acteGroup.auTermeAssembleDecisionActe'] === 'd\'un acte reçu par Me') {
                    tmpText = 'reçu par Me '+ cssVal(varText['acteGroup.nomNotaire']) +" notaire à "+ cssVal(varText['acteGroup.lieuNotaire']);
                } else {
                    tmpText = cssVal(varText['acteGroup.auTermeAssembleDecisionActe']);
                }
                break;
            case "acteGroup.dateEffet":
            case "acteGroup.dateDu":
                tmpText = cssVal(tryToDate(varText[attribute]));
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        societeGroup: [{}],
        siegeSocialGroup: [{}],
    },
    schema: [
        {
            //0
            name: "societeGroup",
            label: "Société",
            type: "group",
            value: [{}],
            children: [
                newInput(def.rcs, '_required', {name: 'societeRcs',
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            capital.model.societeGroup[0] = data.values.societe;
                            capital.model.siegeSocialGroup[0] = data.values.siegeSocial;

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            capital.schema[1].children[3].options = dataCP.options;
                            capital.model.siegeSocialGroup[0].societeVille = dataCP.ville_slug;
                        }
                    }
                }),
                newInput(def.lieuImmatriculation, '_required', {name: 'societeRcsVille'}),
                newInput(def.denomination, '_required', {name: 'societeDenomination'}),
                newInput(def.sigle),
                newInput(def.formesSocietes, '_required', {name: 'societeFormeJuridique'}),
                newInput(def.montantCapital, '_required', {name: 'societeCapital'}),
            ]
        },
        {
            //1
            name: "siegeSocialGroup",
            label: "Siège social",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required', {name: 'societeAdresse'}),
                newInput(def.adresseSuite, '_required', {name: 'societeAdresse2'}),
                newInput(def.codePostal, '_required', {
                    name: 'societeCodePostal',
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            capital.schema[1].children[3].options = data.options;
                            capital.model.siegeSocialGroup[0].societeVille = data.ville_slug;
                            capital.model.societeGroup[0].societeRcsVille = data.greffe;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: 'societeVille'}),
            ]
        },
        {
            //2
            name: "acteGroup",
            label: "Acte",
            type: "group",
            value: [{}],
            children: [
                newInput(def.auTermeAssembleDecisionActe, '_required', {
                    on: {
                        input: (val) => {
                            capital.schema[2].children[1] = val === "6" ? visible(capital.schema[2].children[1]) : hidden(capital.schema[2].children[1]);
                            capital.schema[2].children[2] = val === "6" ? visible(capital.schema[2].children[2]) : hidden(capital.schema[2].children[2]);
                        }
                    }
                }),
                newInput(def.nomNotaire, '_required', {}, 'hidden'),
                newInput(def.lieuNotaire, '_required', {}, 'hidden'),
                newInput(def.dateDu, '_required'),
                newInput(def.dateEffet, '_required'),
            ]
        },
        {
            //3
            name: "modificationCapitalGroup",
            label: "Modification capital",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typeModification, '_required', {
                    on: {
                        input: (val) => {
                            capital.schema[3].children[1] = val === "augmenté" ? visible(capital.schema[3].children[1]) : hidden(capital.schema[3].children[1]);
                            capital.schema[3].children[2] = val === "réduit" ? visible(capital.schema[3].children[2]) : hidden(capital.schema[3].children[2]);
                        }
                    }
                }),
                newInput(def.montantAugmentation, '_required'),
                newInput(def.montantReduction, '_required', {}, 'hidden'),
                newInput(def.nouveauCapital, '_required'),
            ]
        },
    ]
}